import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '../../router/LoadingScreen';
import styles from './Bar.module.css';
import useWPLH from '../../../hooks/useWPLH';

const WPLHSlider = ({
  sliderTitle,
  isDisabled,
  onChange,
  value,
}: {
  sliderTitle: string;
  isDisabled: boolean;
  value: number;
  onChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number,
  ) => void;
}) => {
  return (
    <div className={styles.sliderContainer}>
      <div className={styles.sliderLabel}>
        <Typography
          className="my-8 mr-5 text-2xl"
          align="right"
          id={`${sliderTitle}_slider_name`}
          gutterBottom
        >
          {sliderTitle}
        </Typography>
      </div>
      <div className={styles.sliderBar}>
        <Slider
          value={value}
          getAriaValueText={(ariaValue) => `${ariaValue}C`}
          aria-labelledby={`${sliderTitle}_slider`}
          valueLabelDisplay="auto"
          step={25}
          marks
          min={0}
          max={100}
          disabled={isDisabled}
          classes={{
            root: styles.slider,
            track: styles.track,
            rail: styles.rail,
            mark: styles.mark,
          }}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const Bar = ({ isInteractive }: { isInteractive: boolean }) => {
  const [loading, setLoading] = useState(true);
  const { updateWPLHLevels, wplhDashboard } = useWPLH();

  const [workLevel, setWorkLevel] = useState(wplhDashboard?.workLevel ?? 0);
  const [playLevel, setPlayLevel] = useState(wplhDashboard?.playLevel ?? 0);
  const [loveLevel, setLoveLevel] = useState(wplhDashboard?.loveLevel ?? 0);
  const [healthLevel, setHealthLevel] = useState(
    wplhDashboard?.healthLevel ?? 0,
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (loading === true) {
      (async () => {
        if (!wplhDashboard) {
          navigate('app/journal/activity/wplh');
          toast.error(t('activity.wplh.toasts.doesNotExist'));
          return null;
        }

        setLoading(false);
      })();
    }
  }, [loading]);

  useEffect(() => {
    updateWPLHLevels({
      workLevel,
      playLevel,
      loveLevel,
      healthLevel,
    });
  }, [workLevel, playLevel, loveLevel, healthLevel]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Grid container spacing={1} className={styles.bar}>
      <WPLHSlider
        sliderTitle={t('activity.wplh.work.title')}
        value={workLevel}
        onChange={(_event, value: number) => setWorkLevel(value)}
        isDisabled={!isInteractive}
      />
      <WPLHSlider
        sliderTitle={t('activity.wplh.play.title')}
        value={playLevel}
        onChange={(_event, value: number) => setPlayLevel(value)}
        isDisabled={!isInteractive}
      />{' '}
      <WPLHSlider
        sliderTitle={t('activity.wplh.love.title')}
        value={loveLevel}
        onChange={(_event, value: number) => setLoveLevel(value)}
        isDisabled={!isInteractive}
      />{' '}
      <WPLHSlider
        sliderTitle={t('activity.wplh.health.title')}
        value={healthLevel}
        onChange={(_event, value: number) => setHealthLevel(value)}
        isDisabled={!isInteractive}
      />
      <div className={styles.sliderContainer}>
        <div className={styles.sliderLabel} />
        <div className={styles.sliderBar}>
          <Grid container>
            {['25%', '50%', '75%', '100%'].map((perc) => (
              <Grid
                item
                xs={3}
                className={styles.percentageGrid}
                key={`sliderlabel_${perc}`}
              >
                <div className={styles.percentageDiv}>{perc}</div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default Bar;
