import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Container from '@material-ui/core/Container';

import TopNavbar from '../../../../components/shared/TopNavbar';
import LoadingScreen from '../../../../components/router/LoadingScreen';
import SlideOne from '../../../../components/journal/wplh/slides/SlideOne';
import SlideTwo from '../../../../components/journal/wplh/slides/SlideTwo';
import SlideThree from '../../../../components/journal/wplh/slides/SlideThree';

import uuid from '../../../../utils/uuid';
import useWPLH from '../../../../hooks/useWPLH';

const WPLH = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const {
    createWPLH,
    loadWPLH,
    wplhDashboard,
    isUpdating,
    resetWPLH,
  } = useWPLH();
  const [loading, setLoading] = useState(true);
  const [slide, setSlide] = useState(0);
  const [activityId, setActivityId] = useState(id);

  useEffect(() => {
    resetWPLH();
    if (activityId == null) {
      const newId = uuid();
      createWPLH(newId);
      setActivityId(newId);
    } else {
      loadWPLH(activityId);
    }
    setLoading(false);
  }, [activityId]);

  useEffect(() => {
    if (slide !== 0) return;
    if (loading || isUpdating) return;
    if (wplhDashboard == null && id != null) {
      navigate(-1);
      toast.error(t('activity.wplh.toasts.doesNotExist'));
      return;
    }
    setSlide(1);
  }, [wplhDashboard, loading, isUpdating, setSlide]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>Work Play Love Health Dashboard</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Work Play Love Health Dashboard"
        />
        <meta
          property="og:image"
          content="https://careersocius-portal.web.app/static/da79faebcf479ea362cfb78fa13e6a7e/e8204/wplh.png"
        />
        <meta property="og:title" content="Work Play Love Health Dashboard" />
        <meta property="og:description" content="Try it out now!" />
      </Helmet>

      <TopNavbar />
      <Container className="my-10">
        {slide === 1 && <SlideOne setSlide={setSlide} />}
        {slide === 2 && <SlideTwo setSlide={setSlide} />}
        {slide === 3 && <SlideThree setSlide={setSlide} />}
      </Container>
    </>
  );
};

export default WPLH;
