import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Bar from '../Bar';
import NavigationButton from '../../../shared/NavigationButton';

const SlideTwo = ({
  setSlide,
}: {
  setSlide: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    setSlide(3);
  };

  const handleBack = () => {
    setSlide(1);
  };

  return (
    <div>
      <Grid
        container
        spacing={3}
        className="flex justify-center mt-12 px-12 xl:px-0"
      >
        <Grid item xs={12} className="h-1/2 text-2xl text-center">
          <h4>{t('activity.wplh.slide2.description')}</h4>
        </Grid>

        <Grid item xs={12} className="h-1/2 text-2xl text-center mx-10">
          <Bar isInteractive />
        </Grid>
        <Grid item xs={12}>
          <div className="flex justify-between">
            <NavigationButton onClick={handleBack} />
            <NavigationButton onClick={handleClick} isNext alignRight />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SlideTwo;
