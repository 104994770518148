import { navigate } from 'gatsby';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { MdLightbulbOutline } from 'react-icons/md';
import Alert from '@material-ui/lab/Alert';

import Button from '../../../shared/Button';
import Bar from '../Bar';
import Reflections from '../../Reflections';
import NavigationButton from '../../../shared/NavigationButton';

import useWPLH from '../../../../hooks/useWPLH';
import ModalContext from '../../../../contexts/ModalContext';
import ROOT_URL from '../../../../constants/url';

import styles from './SlideThree.module.css';

const SlideThree = ({
  setSlide,
}: {
  setSlide: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const { updateWPLHReflection, submitWPLH, wplhDashboard } = useWPLH();
  const { emitter, events } = useContext(ModalContext);

  const [loading, setLoading] = useState(true);
  const [reflection, setReflection] = useState(wplhDashboard?.reflection ?? '');
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loading === true) {
      (async () => {
        if (!wplhDashboard) {
          navigate('app/journal/activity/wplh');
          toast.error(t('activity.wplh.toasts.doesNotExist'));
          return null;
        }

        setLoading(false);
      })();
    }
  }, [loading, wplhDashboard]);

  useEffect(() => {
    if (loading === true) {
      return;
    }
    updateWPLHReflection(reflection);
  }, [reflection]);

  const handleClick = () => {
    submitWPLH();
    const link = getSharableUrl();
    const message = t(`modals.share.wplhMessage`);
    emitter.emit(events.WPLH_MODAL, { node: pageRef.current, link, message });
  };

  const handleBack = () => {
    setSlide(2);
  };

  const getSharableUrl = () => {
    const wplhId = wplhDashboard?.id;
    return `${ROOT_URL}/app/journal/activity/wplh/${wplhId}`;
  };

  return (
    <div
      className="mt-12 px-12 xl:px-0"
      ref={pageRef}
      style={{ backgroundColor: 'white' }}
    >
      <Bar isInteractive={false} />
      <Alert
        severity="info"
        variant="standard"
        icon={<MdLightbulbOutline />}
        className={styles.alert}
      >
        <h2>
          <b>{t('activity.wplh.slide3.header2')}</b>
        </h2>
        <h4>{t('activity.wplh.slide3.description4')}</h4>
        <h4>{t('activity.wplh.slide3.description5')}</h4>
      </Alert>
      <Reflections
        prompt={
          <>
            <h2>{t('activity.wplh.slide3.header1')}</h2>
            <p>{t('activity.wplh.slide3.description1')}</p>
            <p>{t('activity.wplh.slide3.description2')}</p>
            <p>{t('activity.wplh.slide3.description3')}</p>
          </>
        }
        id="wplh-reflection"
        reflection={reflection}
        onChange={(event) => setReflection(event.target.value)}
      />
      <div className="col flex justify-between">
        <NavigationButton onClick={handleBack} />
        <div className={styles.submitContainer}>
          <Button onClick={handleClick}>Submit</Button>
        </div>
      </div>
    </div>
  );
};

export default SlideThree;
