import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { Grid } from '@material-ui/core';

import NavigationButton from '../../../shared/NavigationButton';
import styles from './SlideOne.module.css';

const SlideOne = ({
  setSlide,
}: {
  setSlide: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();

  const previews = useStaticQuery(graphql`
    query {
      Work: file(relativePath: { eq: "activities/wplh/Work.png" }) {
        childImageSharp {
          fluid(maxHeight: 75) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      Play: file(relativePath: { eq: "activities/wplh/Play.png" }) {
        childImageSharp {
          fluid(maxHeight: 75) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      Love: file(relativePath: { eq: "activities/wplh/Love.png" }) {
        childImageSharp {
          fluid(maxHeight: 75) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      Health: file(relativePath: { eq: "activities/wplh/Health.png" }) {
        childImageSharp {
          fluid(maxHeight: 75) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const handleClick = () => {
    setSlide(2);
  };

  return (
    <div>
      <Grid item lg={12} className="flex justify-center mt-12 px-12 xl:px-0">
        <div className={styles.container}>
          <Grid className="h-1/2 text-2xl text-center">
            <h2>{t('activity.wplh.title')}</h2>
            <h4>{t('activity.wplh.description')}</h4>
            <h4>{t('activity.wplh.subDescription')}</h4>
            <h5>{t('activity.wplh.subTitle')}</h5>
          </Grid>
        </div>
      </Grid>

      <Grid container className="flex flex-row justify-center mt-16">
        <Grid item md={6} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-1/2 w-1/2 mx-auto align-center"
            fluid={previews.Work.childImageSharp.fluid}
          />

          <div className={styles.container}>
            <h5>{t('activity.wplh.work.title')}</h5>
            <p>{t('activity.wplh.work.description')}</p>
          </div>
        </Grid>

        <Grid item md={6} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-1/2 w-1/2 mx-auto"
            fluid={previews.Play.childImageSharp.fluid}
          />

          <div className={styles.container}>
            <h5>{t('activity.wplh.play.title')}</h5>
            <p>{t('activity.wplh.play.description')}</p>
          </div>
        </Grid>

        <Grid item md={6} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-1/2 w-1/2 mx-auto"
            fluid={previews.Love.childImageSharp.fluid}
          />

          <div className={styles.container}>
            <h5>{t('activity.wplh.love.title')}</h5>
            <p>{t('activity.wplh.love.description')}</p>
          </div>
        </Grid>

        <Grid item md={6} lg={2}>
          <GatsbyImage
            loading="eager"
            className="h-1/2 w-1/2 mx-auto"
            fluid={previews.Health.childImageSharp.fluid}
          />

          <div className={styles.container}>
            <h5>{t('activity.wplh.health.title')}</h5>
            <p>{t('activity.wplh.health.description')}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <NavigationButton onClick={handleClick} isNext alignRight />
        </Grid>
      </Grid>
    </div>
  );
};

export default SlideOne;
